import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import css from './PrimaryNav.module.scss'
import { useClickOutsideListenerRef } from '@/utils/useClickOutsideListener'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { MASTERPLAN_LANDING_ENDPOINT, liveGifar, liveGifen } from '@/utils/constant'
import IconForwardNav from '@/assets/icons/IconForwardNav'
import { observerService } from '@/utils/observer'
const SupportDropdown = dynamic(() => import('./SupportDropdown'))
const IconoGraphy = dynamic(()=>import('../IconoGraphy'))

let ClientMethod = null
let importMethods = null
const PrimaryNav = (props) => {
  const { navbarOpen, handleNavbar, page, headerMenuCities, statusTag, showStickyMobileHeader } = props
  const { t, lang } = useTranslation('translations-auction')
  const [activeMenu, setActiveMenu] = useState(null)
  const [findToggle, setFindToggle] = useState(false)
  const [autionToggle, setAutionToggle] = useState(false)
  const [forSale, setForSale] = useState(false)
  const [forRent, setForRent] = useState(false)
  const [forInternational, setForInternational] = useState(false)
  const [headerCitiesNavbar, setHeaderCitiesNavbar] = useState([])
  const [showSupportDropdown, setshowSupportDropdown] = useState(false)
  const [MegamenuDesktop, setMegamenuDesktop] = useState(null)
  const isDesktop = useMediaQuery({ minWidth: 980 })
  const liveLabel = lang === 'en' ? liveGifen : liveGifar
  const router = useRouter()
  const hideAuctionSelectedUser =  router.asPath.split("/").includes("user")
  const handleMegaMenu = () => {
    setActiveMenu('')
    setForSale(false)
    setForInternational(false)
    setFindToggle(false)
    if (isDesktop) {
      setHeaderCitiesNavbar([])
    }
  }
  const refMegaMenu = useClickOutsideListenerRef(handleMegaMenu)
  let textColor = css.navbar__lightheader
  let hamburgerClrNav = css.lightnavbtn
  

  const dynamicImportMethods = async () => {
    if(!importMethods) {
      importMethods = await import(/*webpackChunkName: "Header ImportMethods" */ './Methods/ImportMethods')
    }
  }

  const loadDynamicContent = async () => {
    await dynamicImportMethods()
    if(headerCitiesNavbar && !MegamenuDesktop) {importMethods.importMegamenuDesktop({ setMegamenuDesktop })}
  }

  useEffect(() => {
    loadDynamicContent();
  },[headerCitiesNavbar])

  const importClientSideMethods = async () => {
    if(!ClientMethod) {
      ClientMethod = await import(/*webpackChunkName : "ClientSideMethod"*/'./Methods/PrimaryNavClientMethod')
    }
  }

  // manage countries list
  const getMegamenuData = async(temp, forType) => {
    await importClientSideMethods()
    ClientMethod.getMegamenuData({temp, forType, setForSale, setForRent, setForInternational, setHeaderCitiesNavbar, forSale, forRent, forInternational, headerMenuCities})
  }

  // common action to manage NavAction
  const commonNavAction = async(tempFlag) => {
    await importClientSideMethods()
    ClientMethod.commonNavAction({tempFlag, setActiveMenu, setFindToggle, setAutionToggle, setshowSupportDropdown, findToggle, autionToggle, showSupportDropdown})
  }

  const getPropertyType = () => forRent ? t('common.FOR_RENT') : t('common.INTERNATIONAL')

  return (
    <nav className={`${css.navbar} ${showStickyMobileHeader ? css.isNotDesktop: ''}`}>
        {!showStickyMobileHeader && <div className={css.isNotMobile}>
            <button
              aria-label='menu'
              type='button'
              className={css.navbar__control}
              onClick={() => handleNavbar(navbarOpen, true)}
            >
              <span className={`${css.hamburger}  ${hamburgerClrNav}`}></span>
            </button>
          </div>
        }
      {<div className={`${css.navbar__content} ${css.isNotDesktop}`}>
        <div className={css.desktopview}>
          <div className={css.has_dropdown} ref={refMegaMenu}>
            <div
              className={`${css.navbar__link} ${textColor} ${activeMenu === 'find' ? css.active : ''}`}
              onClick={() => commonNavAction(0)}
            >
              {t('globalNavigation.FIND')}
            </div>

            <div className={`${css.dropdown}  ${findToggle ? css.headerNav : ''}`}>
              <div
                className={`${css.dropdown_content} ${css.dropdown_finddrop} ${
                  forSale || forRent || forInternational ? css.megamenuopen : ''
                }`}
              >
                <div className={css.dropdown_innerrow}>
                  <div className={css.dropdown_leftsec}>
                    <a
                      href='#'
                      onClick={(e) => {
                        e.preventDefault()
                        getMegamenuData(0, t('globalNavigation.FOR_SALE'))
                      }}
                    >
                      <div className={css.navbar__dropdown__dropdownitem}>
                        <span>
                          <IconoGraphy iconClass={'icon-sale'} iconColor={'color_purple'}></IconoGraphy>
                        </span>
                        <span className={css.navbar__dropdown__dropdownitem__text}>
                          {t('globalNavigation.FOR_BUY_LABEL_SIDEBAR')}
                          <p> {t('globalNavigation.FOR_SALE_SUBTITLE')}</p>
                        </span>
                        <IconForwardNav />
                      </div>
                    </a>
                    <a
                      href='#'
                      onClick={(e) => {
                        e.preventDefault()
                        getMegamenuData(1, t('globalNavigation.FOR_RENT'))
                      }}
                    >
                      <div className={css.navbar__dropdown__dropdownitem}>
                        <span>
                          <IconoGraphy
                            iconClass={`icon-rent`}
                            iconColor={'color_newViolet'}
                            fontSize={'f_24'}
                          ></IconoGraphy>
                        </span>
                        <span className={css.navbar__dropdown__dropdownitem__text}>
                          {t('globalNavigation.FOR_RENT_LABEL_SIDEBAR')}
                          <p> {t('globalNavigation.FOR_RENT_SUBTITLE')}</p>
                        </span>
                        <IconForwardNav />
                      </div>
                    </a>

                    {process.env.NEXT_PUBLIC_DISABLE_INTERNATIONAL == 'false' && (
                      <a
                        href='#'
                        onClick={(e) => {
                          e.preventDefault()
                          getMegamenuData(2, t('globalNavigation.INTERNATIONAL'))
                        }}
                      >
                        <div className={css.navbar__dropdown__dropdownitem}>
                          <span>
                            <IconoGraphy iconClass={'icon-globe-map'} iconColor={'color_purple'}></IconoGraphy>
                          </span>
                          <span className={css.navbar__dropdown__dropdownitem__text}>
                            {t('globalNavigation.INTERNATIONAL')}
                            <p> {t('globalNavigation.INTERNATIONAL_SUBTITLE')}</p>
                          </span>
                          <IconForwardNav />
                        </div>
                      </a>
                    )}
                  </div>

                  <div className={css.dropdown_rightsec}>
                    {headerCitiesNavbar && MegamenuDesktop && (
                      <MegamenuDesktop.default
                        navLinks={headerCitiesNavbar}
                        handleNavbar={handleNavbar}
                        navbarOpen={navbarOpen}
                        navTitle={
                          forSale ? t('common.FOR_SALE') : getPropertyType()
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {process.env.NEXT_PUBLIC_DISABLE_AUCTION_FLOW == 'false' && (
            <div
              className={`${css.navbar__link} ${textColor} ${css.has_dropdown} ${!hideAuctionSelectedUser ? css.auctionSelected : ''}`}
              onClick={() => {
                commonNavAction(1)
                observerService.handleEvent({type: 'home'});
                router.push(`/`)
              }}
            >
              {t('globalNavigation.AUCTIONS')}
              {statusTag === "live" && <Image src={liveLabel} width={32} height={20} className={css.liveLabelAnimate} alt=''/>}
              {statusTag === "upcoming" && <div className={css.upcomingPulse}>
              <div className={css.PulseAnime}></div>
              </div>}
            </div>
          )}
          <a
            href={new URL((lang == 'en' ? '/en' : '') + MASTERPLAN_LANDING_ENDPOINT, process.env.NEXT_PUBLIC_BASE_URL)}
            className={`${css.navbar__link} ${textColor} ${activeMenu === 'auctions' ? css.active : ''}`}
          >
            {t('globalNavigation.MASTER_PLAN')}
          </a>

          <div className={css.actionBtns}>
            <div
              className={`${css.navbar__link} ${textColor}`}
              onClick={(e) => {
                e.stopPropagation()
                commonNavAction(2)
              }}
            >
              {t('globalNavigation.SUPPORT')}
              {showSupportDropdown && (
                <SupportDropdown
                  page={page}
                  onClose={() => {
                    setshowSupportDropdown(false)
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    }
    </nav>
  )
}

export default PrimaryNav
